/* eslint-disable react/prop-types */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  FormControlLabel,
  Radio,
  Button,
  RadioGroup,
  DialogActions,
} from "@material-ui/core";
import icons from "../../../../../assets/icons/icon-collection.svg";
import "./applicant.css";
import { ternaryResolver } from "../../../../../utils/helpers";
import { logProfileVisits } from "../../../../../redux/actions/insightActions";
import { getOtherUserAction } from "../../../../../redux/actions/profileActions";
import {
  getAllExperienceAction,
  getSingleExperienceAction,
} from "../../../../../redux/actions/experienceAction";
import CustomButton from "../../../../../components/CustomButton";
import { uploadFile } from "../../../../../utils/helpers";
import BackButton from "../../../../../components/BackButton";
// import Button from "react-bootstrap";
import CustomTextArea from "../../../../../components/CustomTextArea";
import { SectionLoader } from "../../../../../components/PageLoader";
import { func } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUpdated,
  declineApplication,
  getJobApplicants,
  hireApplicant,
  updateApplication,
} from "../../../../../redux/actions/insightActions";
import { useHistory, useParams } from "react-router-dom";
import { postHiring } from "../../../../../redux/actions/jobActions";
import { getJob } from "../../../../../redux/actions/jobActions";
import CustomInput from "../../../../../components/CustomInput";
import cogoToast from "cogo-toast";

const Reason = ({ setMessage }) => {
  const [reason, setReason] = useState("");

  const handleChangeReason = ({ target: { value } }) => {
    setReason(value);
    setMessage(value);
  };

  return (
    <div className="mb-3">
      <CustomTextArea
        placeholder="Enter Message"
        onChange={handleChangeReason}
        value={reason}
        label=""
        id=""
      />
      {/* <div className="d-flex">
        <button className="w-40 co-primary borderless bg-white">
          <b>Upload File</b>
        </button>
      </div> */}
    </div>
  );
};

Reason.propTypes = {
  setSuccess: func.isRequired,
};

const Applicants = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, stage } = useParams();
  const [open, setOpen] = useState(false);
  const { user } = useSelector(({ profile }) => profile);
  const [openMain, setOpenMain] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState(0);
  const [salaryAgreement, setSalaryAgreement] = useState(false);
  const [imageUpload, setImageUpload] = useState(null);
  const [currentApplicant, setCurrentApplicant] = useState();
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [salary, setSalary] = useState("");
  const [salaryPlacement, setSalaryPlacement] = useState(0);
  const [openPlacement, setOpenPlacement] = useState(false);
  const [hiring, setHiring] = useState(false);

  const [experiences, setExperiences] = useState([]);
  const [checkIn, setCheckIn] = useState();

  const [hiringPost, setHiringPost] = useState({
    user_id: "",
    applicant_name: "",
    applicant_id: "",
    job_id: "",
    organisation: "",
    position: "",
    salaryID: "",
    salary_approved: "",
    contract_url: "",
    placement_fee: "",
  });
  const { applicants, updated } = useSelector(({ insight }) => insight);
  const { job } = useSelector((state) => state.job);
  const [idProfile, setIdProfile] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [openDialog, setOpenDialogue] = useState(false);
  const [salaryApproved, setSalaryApproved] = useState(false);
  const [agreedSalary, setAgreedSalary] = useState("");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleGetExperience = async (idProfile) => {
    console.log(idProfile);
    try {
      const response = await getSingleExperienceAction(idProfile, dispatch);
      if (response && response.payload) {
        setExperiences(response.payload);
      } else {
        setExperiences([]);
      }
    } catch (error) {
      console.error(error);
      setExperiences([]);
    }
  };
  useEffect(() => {
    if (idProfile) {
      getOtherUserAction(dispatch, idProfile);
      logProfileVisits(idProfile);
    }
  }, [dispatch, idProfile]);
  const handleOpenProfile = async (id) => {
    try {
      setOpenDialogue(true);
      setIdProfile(id);
      await handleGetExperience(id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseProfile = () => {
    setOpenDialogue(false);
  };
  useEffect(() => {
    getJob(dispatch, id);
  }, [dispatch, id]);
  const handleFileChange = async (e, applicant, job) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    console.log(reader.result);
    reader.onload = () => {
      setFilePreview(reader.result);
    };
    console.log(reader.result);
    reader.readAsDataURL(file);
    if (file) {
      Promise.resolve(
        uploadFile(
          file,
          "https://api.cloudinary.com/v1_1/workbrook-hash/raw/upload"
        )
      )
        .then(({ data, success }) => {
          if (success) {
            console.log(data);
            console.log(data);
            setImageUpload(data);
          } else {
            cogoToast.error("Unsuccessful document upload", {
              hideAfter: 4,
              position: "top-center",
            });
          }
        })
        .finally(() => {});
    }
  };

  const handleSalaryApprovalChange = (e) => {
    console.log(e.target.value);
    const value = e.target.value;
    setHiringPost((prevState) => ({
      ...prevState,
      salary_approved: value,
    }));
    setSalaryApproved(value === "yes");
  };
  const handleHiringDetails = (
    applicant_name,
    applicant_id,
    jobId,
    jobCompany,
    jobRole
  ) => {
    const userDataString = sessionStorage.getItem("userData");
    const userData = JSON.parse(userDataString);
    const profileId = Number(userData.id);
    const placement_fee = hiringPost.salaryID * 0.1;

    const data = {
      user_id: profileId,
      applicant_name: applicant_name,
      applicant_id: Number(applicant_id),
      job_id: Number(jobId),
      organisation: jobCompany,
      position: jobRole,
      salary: Number(hiringPost.salaryID),
      salary_approved: hiringPost.salary_approved,
      contract_url: imageUpload,
      placement_fee: placement_fee,
    };
    console.log(data);
    postHiring(dispatch, data)
      .then(async (action) => {
        console.log("Hiring details posted successfully:", action.payload);
        // await handleHire();
        setSalaryPlacement(placement_fee);
        setOpen(false);
        setOpenMain(false);
        setOpenModal(false);
        setOpenPlacement(true);
      })
      .catch((error) => {
        console.error("Error posting hiring details:", error);
      });
  };
  const handleHire = () => {
    const hire = async () => {
      
      const data = {
        job_id: parseInt(id, 10),
        applicant_id: Number(currentApplicant.applicant_id),
        message,
        salary: Number(salary) || Number(hiringPost.salary_approved),
        // isRecruiterJob: 0,
      };
      // if (job?.isToRefTimeLineOrToHireRecruter !== "2") {
      //   delete data.salary;
      // }
      setHiring(true);
      await hireApplicant(dispatch, data);
      if (updated) {
        handleClose();
      }
      setHiring(false);
      // setOpen(false);
    };
    hire();
  };

  const handleAgreement = (e) => {
    setSalaryAgreement((prevAgreement) => !prevAgreement);
  };

  const handleAgreedSalaryChange = (e) => {
    if (e.target) {
      // Add a null check
      setSalary(e.target.value);
      setHiringPost((prevState) => ({
        ...prevState,
        salary: e.target.value,
      }));
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModal = (applicant) => {
    console.log(applicant, "applicant");
    setCurrentApplicant(applicant);
    setOpen(false);
    setOpenModal(true);
    setOpenMain(true);
  };
  const handleSetMessage = (message) => {
    setMessage(message);
  };
  const handleClosePlacement = () => {
    setOpenPlacement(false);
  };

  useEffect(() => {
    let applStage;
    switch (stage) {
      case "consider":
        applStage = "1";
        break;
      case "test":
        applStage = "2";
        break;
      case "interview":
        applStage = "3";
        break;
      case "hired":
        applStage = "4";
        break;
      case "regret":
        applStage = "5";
        break;
      case "regret":
        applStage = "6";
        break;
      default:
        applStage = "*";
        break;
    }
    const getApplicants = async () => {
      setLoading(true);
      await getJobApplicants(dispatch, id, applStage);
      setLoading(false);
    };
    getApplicants();
  }, [job, stage]);

  useEffect(() => {
    getJob(dispatch, id);
  }, [dispatch, id]);

  const jobApplicants = applicants ? applicants["Applicants"] : [];
  // console.log("Job ID", applicants);

  const handleChange = (event) => {
    let val = parseInt(event.target.value);
    setValue(val);
    // console.log("val", value);
  };
  const handleHiringPost = (event) => {
    const { name, value } = event.target;
    setHiringPost((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleReason = (reason) => {
    setReason(reason);
    // console.log("reason", reason);
  };

  const handleOpen = (applicant) => {
    setCurrentApplicant(applicant);
    setOpenModal(false);
    setOpen(true);
  };
  const handleOpenMain = (applicant) => {
    setCurrentApplicant(applicant);
    setOpenMain(true);
  };

  const handleClose = () => {
    clearUpdated(dispatch);
    setSuccess(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const updateApplData = {
      job_id: parseInt(id, 10),
      stage_code: parseInt(value, 10),
      applicant_id: parseInt(currentApplicant.applicant_id, 10),
      reason: reason,
    };

    // console.log("updateApplData", updateApplData);

    await updateApplication(dispatch, updateApplData);
    if (updated) {
      handleClose();
    }
  };

  const rejectApplication = async () => {
    await declineApplication(dispatch, id, currentApplicant.applicant_id);
    if (updated) {
      handleClose();
    }
  };

  return (
    <div className="position-relative w-100">
      <div className="d-flex justify-content-between filters mb-4">
        {job?.isToRefTimeLineOrToHireRecruter !== "2" && (
          <>
            <button
              style={{ width: "70px" }}
              onClick={() => {
                history.push(`/insights/jobs/applicants/${id}`);
                handleTabClick("all");
              }}
              className={activeTab === "all" ? "active" : ""}
            >
              All
            </button>
            <button
              style={{ width: "70px" }}
              onClick={() => {
                history.push(`/insights/jobs/applicants/${id}/consider`);
                handleTabClick("consider");
              }}
              className={activeTab === "consider" ? "active" : ""}
            >
              Consider
            </button>
            <button
              style={{ width: "70px" }}
              onClick={() => {
                history.push(`/insights/jobs/applicants/${id}/test`);
                handleTabClick("test");
              }}
              className={activeTab === "test" ? "active" : ""}
            >
              Test
            </button>
            <button
              style={{ width: "70px" }}
              onClick={() => {
                history.push(`/insights/jobs/applicants/${id}/interview`);
                handleTabClick("interview");
              }}
              className={activeTab === "interview" ? "active" : ""}
            >
              Interview
            </button>
            <button
              style={{ width: "70px" }}
              onClick={() => {
                history.push(`/insights/jobs/applicants/${id}/regret`);
                handleTabClick("regret");
              }}
              className={activeTab === "regret" ? "active" : ""}
            >
              Regret
            </button>
          </>
        )}
      </div>
      {loading && <SectionLoader />}
      {!loading && !jobApplicants && (
        <div className="d-flex align-items-center cursor-pointer justify-content-center mb-3">
          <p className="text-center">No applicants</p>
        </div>
      )}
      {!loading &&
        jobApplicants &&
        jobApplicants[0] &&
        jobApplicants.map((applicant) => (
          <div
            key={applicant.id}
            onClick={() => handleOpen(applicant)}
            className="d-flex align-items-center cursor-pointer justify-content-between mb-3"
          >
            <span className="d-flex align-items-center">
              <img width="50" src={applicant.image_url} alt="avatar" />
              <span className="d-flex flex-column ml-3">
                <h6 className="mb-0">
                  {applicant.first_name} {applicant.last_name}
                </h6>
                <span className="co-gray small-text">
                  @{applicant.username}
                </span>
              </span>
            </span>
            <span>
              <CustomButton
                text={
                  job?.isToRefTimeLineOrToHireRecruter === "2" ? "Hire" : "Move"
                }
                alt="Move"
                type="button"
                backgroundColor="white"
                borderColor="var(--primary-color)"
                additionalClass={"px-4"}
              ></CustomButton>
            </span>
          </div>
        ))}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "40rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
        {currentApplicant && (
          <div className="d-flex justify-content-between align-items-centter">
            <DialogTitle id="form-dialog-title">
              {" "}
              {!success && (
                <span className="w-100 d-flex align-items-center">
                  <img
                    width="50"
                    src={currentApplicant.image_url}
                    alt="avatar"
                  />
                  <span className="d-flex flex-column ml-3">
                    <h6 className="mb-0">
                      {currentApplicant.first_name} {currentApplicant.last_name}
                    </h6>
                    <CustomButton
                      handleClick={
                        () => handleOpenProfile(currentApplicant.applicant_id)
                        // history.push(
                        //   `/profile/${currentApplicant.applicant_id}`
                        // )
                      }
                      backgroundColor="#3A6ED4"
                      color="#fff"
                      text="View Profile"
                      additionalClass="mt-2 p-1 small-text"
                    />
                  </span>
                </span>
              )}
            </DialogTitle>
            <div className="close-button">
              <IconButton onClick={handleClose}>
                <svg width="30" height="30" className="rounded-circle">
                  <use href={`${icons}#close`}></use>
                </svg>
              </IconButton>
            </div>
          </div>
        )}
        {/* {(declined || updated) && ( */}
        {/* <DialogContent>
          <div className="d-flex flex-column justify-content-center align-items-center mx-auto my-5">
            <svg width="119" height="119" className="rounded-circle">
              <use href={`${icons}#checkmark`}></use>
            </svg>
            <p className="co-green mt-2 small-text">
              Successfully {updated ? "updated" : "declined"}
            </p>
          </div>
        </DialogContent> */}
        {/* )}
        {!declined && !updated && ( */}
        <DialogContent>
          <RadioGroup
            aria-label="change statue"
            name="status"
            value={value}
            onChange={handleChange}
          >
            {job?.isToRefTimeLineOrToHireRecruter !== "2" && (
              <>
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="Consider"
                />
                {value === 1 && (
                  <Reason
                    key={1}
                    setSuccess={setSuccess}
                    handleReason={handleReason}
                    setMessage={handleSetMessage}
                  />
                )}
                {/* <Reason setSuccess={setSuccess} /> */}
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label="Test"
                />
                {value === 2 && (
                  <Reason
                    key={2}
                    setSuccess={setSuccess}
                    handleReason={handleReason}
                    setMessage={handleSetMessage}
                  />
                )}
                <FormControlLabel
                  value={3}
                  control={<Radio color="primary" />}
                  label="Interview"
                />
                {value === 3 && (
                  <Reason
                    key={3}
                    setSuccess={setSuccess}
                    handleReason={handleReason}
                    setMessage={handleSetMessage}
                  />
                )}
              </>
            )}
            <FormControlLabel
              value={4}
              control={<Radio color="primary" />}
              label="Hire"
            />
           {value === 4 && (
  <>
    {job?.isToRefTimeLineOrToHireRecruter === "2" && (
      <>
        <CustomInput
          type="text"
          onChange={(e) => setSalary(e.target.value)}
          name="salary"
          value={salary}
          placeholder=""
          label="Salary"
          id="salary"
        />

        <Reason
          key={4}
          setSuccess={setSuccess}
          handleReason={handleReason}
          setMessage={handleSetMessage}
        />
      </>
    )}
  </>
)}

          </RadioGroup>
        </DialogContent>
        {/* )} */}
        {!success && (
          <DialogActions className="mt-5">
            <span className="w-100 mr-4">
              <span className="d-flex">
                <CustomButton
                  type="button"
                  handleClick={rejectApplication}
                  backgroundColor="#FF5964"
                  color="#fff"
                  text="Decline Application"
                  additionalClass="mx-3 mt-2"
                />
                {value !== 4 && (
                  <CustomButton
                    type="button"
                    handleClick={handleSubmit}
                    disabled={value === 0}
                    backgroundColor="#3a6ed4"
                    color="#fff"
                    text="Move Applicant"
                    additionalClass="mx-3 mt-2"
                  />
                )}
                {value === 4 && (
                  <CustomButton
                    type="button"
                    handleClick={() => handleOpenModal(currentApplicant)}
                    // handleClick={handleHire}
                    disabled={value === 0}
                    backgroundColor="#3a6ed4"
                    color="#fff"
                    text="Hire Applicant"
                    additionalClass="mx-3 mt-2"
                    loading={hiring}
                  />
                )}
              </span>
            </span>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: "58rem",
            borderRadius: "1rem",
            padding: "0.75rem",
          },
        }}
      >
    <Dialog
  open={openModal}
  onClose={handleClose}
  aria-labelledby="form-dialog-title"
  PaperProps={{
    style: {
      width: "58rem",
      borderRadius: "1rem",
      padding: "0.75rem",
    },
  }}
>
  {currentApplicant && jobApplicants && jobApplicants.length > 0 && (
    <div className="container d-flex justify-content-between align-items-center">
      <DialogTitle style={{ marginTop: "4%" }} id="form-dialog-title">
        <strong className="text-dark">
          Hiring Questionnaire On Candidate
          <br />
        </strong>

        {!success && (
          <div className="mt-2">
          
            <h5 style={{ fontSize: "13px", fontWeight: "bold" }} className="text-dark">
              Name of Applicant
            </h5>
            <input
              className="p-2 form-control"
              onChange={handleHiringPost}
              name="applicant_name"
              value={`${currentApplicant.first_name} ${currentApplicant.last_name}`}
            />

            <br />

         
            <h5 style={{ fontSize: "13px", fontWeight: "bold" }} className="mt-1 text-dark">
              Name of Organization
            </h5>
            <input
              className="p-2 form-control"
              value={job.campany_name}
              onChange={handleHiringPost}
              name="organisation"
            />

            <br />

           
            <h5 className="mt-1 text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>
              Position to be held
            </h5>
            <input
              className="p-2 form-control"
              value={job.role_title}
              onChange={handleHiringPost}
              name="position"
            />

            <br />

            <h5 className="mt-1 text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>
              Has Salary been Approved?
            </h5>

            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="salaryApproved"
                id="yes"
                value="yes"
                onChange={handleSalaryApprovalChange}
                checked={salaryApproved}
              />
              <label className="form-check-label" htmlFor="yes">Yes</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="salaryApproved"
                id="no"
                value="no"
                onChange={handleSalaryApprovalChange}
                checked={!salaryApproved}
              />
              <label className="form-check-label" htmlFor="no">No</label>
            </div>

         
            {salaryApproved && (
              <div className="mt-4">
                <h5 className="mt-1 text-dark" style={{ fontSize: "13px", fontWeight: "bold" }}>
                  Agreed Salary
                </h5>
                <input
                  type="text"
                  name="salaryID"
                  className="form-control"
                  value={hiringPost.salaryID}
                  onChange={handleHiringPost}
                  placeholder="Enter agreed salary"
                />
              </div>
            )}

            <br />

         
            <h5 className="mt-1 text-dark mt-3" style={{ fontSize: "13px", fontWeight: "bold" }}>
              Please upload the contract of employment below
            </h5>
            <input
              type="file"
              id="fileInput"
              onChange={(e) => handleFileChange(e, currentApplicant, job)}
              accept=".pdf,.doc,.docx,.txt,.rtf,image/*"
            />

            <br />

            
            <div className="form-check form-check-inline mt-4 mb-4">
              <input className="form-check-input" type="radio" onChange={handleAgreement} checked={salaryAgreement} />
              <label style={{ fontSize: "13px", fontWeight: "900", color: "red" }} className="form-check-label">
                I agree that a placement fee will be deducted from the approved salary
              </label>
            </div>

            <br />

          
            <CustomButton
              backgroundColor="#3A6ED4"
              color="#fff"
              text="Submit"
              disabled={!salaryAgreement || !hiringPost.salary_approved || !imageUpload}
              handleClick={() =>
                handleHiringDetails(
                  currentApplicant.first_name,
                  currentApplicant.id,
                  job.id,
                  job.company_name,
                  job.role_title
                )
              }
            />
          </div>
        )}
      </DialogTitle>
    </div>
  )}
</Dialog>



        
      </Dialog>
      <Dialog open={openPlacement} onClose={handleClosePlacement}>
        <DialogTitle id="form-dialog-title ">Important Information</DialogTitle>
        <DialogContent>
          <strong>
            Please note that a placement fee of{" "}
            <span className="text-danger">{salaryPlacement} </span>
            has been deducted from your salary
          </strong>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center align-center">
          <Button onClick={handleClosePlacement} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleCloseProfile}>
        <DialogTitle id="form-dialog-title">Candidate's Profile</DialogTitle>
        <DialogContent>
          {user && (
            <div className="mt-3 px-2">
              <div className="profile-header d-flex">
                <img height="150" className="mr-5" src={user.image_url} />
                <div className="d-flex flex-column mt-2">
                  <h4>
                    {user.first_name} <b>{user.last_name}</b>
                  </h4>
                  {/* <span className="co-gray">Project Manager</span> */}
                </div>
              </div>
            </div>
          )}
          <div className="profile-about">
            <div className="section-header mt-5">
              <p className="mb-0">About</p>
            </div>
            <p className="co-darkgray mt-4">
              {user?.about || "Nothing to display"}
            </p>
          </div>
          <div className="profile-education">
            <div className="section-header mt-5">
              <p className="mb-0">EDUCATION/CERTIFICATIONS</p>
            </div>
            {user?.education.length ? (
              user.education.map((data, index) => (
                <div className={`education edu_link${index + 1}`} key={data.id}>
                  <p className="co-darkgray medium-text">{data.school}</p>
                  <p>{`${data.degree} ${data.field_of_study} (${ternaryResolver(
                    `${data.end_date}`.toLowerCase() === "present",
                    data.end_date,
                    new Date(data?.end_date).getFullYear()
                  )})`}</p>
                </div>
              ))
            ) : (
              <div className="mt-4">
                <p className="co-darkgray">Nothing to display</p>
              </div>
            )}
          </div>
          <div className="profile-skills">
            <div className="section-header mt-5">
              <p className="mb-0">SKILLS/INTERESTS</p>
            </div>
            <ul className="d-flex flex-wrap mt-3">
              {user?.skills?.length ? (
                user?.skills?.map((skill) => (
                  <li
                    key={skill}
                    className="mr-2 py-1 px-2 outlined"
                    style={{ marginTop: "10px" }}
                  >
                    {skill}
                  </li>
                ))
              ) : (
                <li className="mr-2 py-1 px-2 co-darkgray">No skill added</li>
              )}
            </ul>
          </div>
          <div className="profile-documents">
            <div className="section-header mt-5">
              <p className="mb-0">RESUME/DOCUMENTS</p>
            </div>
            <div className="d-flex flex-column mt-3">
              {user?.resume && (
                <a className="d-flex my-2" href={user.resume} download>
                  <svg width="20" height="24" className="mr-2">
                    <use href={`${icons}#file`}></use>
                  </svg>
                  Rèsumé
                  <span className="flag mx-3 co-primary small-text px-1">
                    Default
                  </span>
                </a>
              )}
              {user?.links && (
                <span className="d-flex my-2">
                  <svg width="22" height="22" className="mr-2">
                    <use href={`${icons}#link`}></use>
                  </svg>
                  {user?.links[0]?.facebook && (
                    <a
                      className="underlined co-primary mr-2"
                      href={user.links[0].facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  )}
                  {user?.links[0]?.linkdn && (
                    <a
                      className="underlined co-primary mr-2"
                      href={user.links[0].linkdn}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                  )}
                  {user?.links[0]?.twitter && (
                    <a
                      className="underlined co-primary mr-2"
                      href={user.links[0].twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  )}
                  {user?.links[0]?.potfolio && (
                    <a
                      className="underlined co-primary"
                      href={user.links[0].potfolio}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Portfolio
                    </a>
                  )}
                  {[
                    !user?.resume,
                    !user?.links[0]?.facebook,
                    !user?.links[0]?.linkdn,
                    !user?.links[0]?.twitter,
                    !user?.links[0]?.potfolio,
                  ].every((val) => val === true) && (
                    <p className="mr-2 py-1 px-2 co-darkgray">
                      Nothing to display
                    </p>
                  )}
                </span>
              )}
            </div>
          </div>
          <div className="profile-education">
            <div className="section-header mt-5">
              <p className="mb-0">EXPERIENCES</p>
            </div>
            {Array.isArray(experiences) && experiences?.length ? (
              experiences.map((data, index) => (
                <div className={`education edu_link${index + 1}`} key={data.id}>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p style={{ color: "#3A6ED4" }}>COMPANY NAME</p>
                      <p className="co-darkgray medium-text">
                        {data.campany_name}
                      </p>
                    </div>
                    <div>
                      <p style={{ color: "#3A6ED4" }}>JOB POSITION</p>
                      <p>{data.title}</p>
                    </div>
                    <div>
                      <p style={{ color: "#3A6ED4" }}>START DATE</p>
                      <p>{data.start_date}</p>
                    </div>
                    <div>
                      <p style={{ color: "#3A6ED4" }}>END DATE</p>
                      <p>{data.end_date}</p>
                    </div>
                  </div>
                  <div>
                    <p style={{ color: "#3A6ED4" }}>ACHIEVEMENTS</p>
                    <p>{data.achievements}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="mt-4">
                <p className="co-darkgray">Nothing to display</p>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center align-center">
          <Button onClick={handleCloseProfile} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPlacement} onClose={handleClosePlacement}>
        <DialogTitle id="form-dialog-title ">Important Information</DialogTitle>
        <DialogContent>
          <strong>
            Please note that a placement fee of{" "}
            <span className="text-danger">{salaryPlacement} </span>
            has been deducted from your salary
          </strong>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center align-center">
          <Button onClick={handleClosePlacement} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Applicants;
