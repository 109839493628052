import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { bool, func, object, string } from 'prop-types';


const CustomSelect = ({ handleChange, multiple, options, id, label, currentValue,defaultValue, name }) => {
  const listOptions = () => {
    return Object.entries(options).map(([key, value], index) => (
      <MenuItem key={index.toString()} value={key}>{value}</MenuItem>
    ));
  };

  return (
    <FormControl variant="standard" className="custom-input w-25 w-100 mt-3">
      <label className="d-block mb-0">{label}</label>
      <Select
        id={id}
        label={label}
        name={name}
        onChange={handleChange}
        value={currentValue }
        defaultValue={defaultValue}
        multiple={multiple}
      >
        {listOptions()}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  handleChange: func.isRequired,
  options: object.isRequired,
  id: string.isRequired,
  label: string.isRequired,
  currentValue: string.isRequired,
  name: string.isRequired,
  multiple: bool
};

CustomSelect.defaultProps = {
  multiple: false,
  options: {} 
};

export default CustomSelect;
